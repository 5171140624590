import React from 'react'
import Layout from '../../components/layouts/blog'
import PostCard from '../../components/PostCard'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

function Latest({ data }) {
	return (
		<>
			<Helmet>
				<title>Classify — Blog</title>
				<meta
					name="description"
					content="Stories, guides and ideas written to broaden perspectives and provide helpful insights. 🔥"
				/>
				<link
					rel="canonical"
					href="https://classify.org.uk/blog/latest"
				/>

				<meta property="og:title" content="Classify — Blog" />
				<meta
					property="og:url"
					content="https://classify.org.uk/blog/latest"
				/>
				<meta
					property="og:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="og:description"
					content="Stories, guides and ideas written to broaden perspectives and provide helpful insights. 🔥"
				/>
				<meta property="og:type" content="website" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@classifyapp" />
				<meta property="twitter:title" content="Classify — Blog" />
				<meta
					name="twitter:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="twitter:description"
					content="Stories, guides and ideas written to broaden perspectives and provide helpful insights. 🔥"
				/>
			</Helmet>
			<Layout>
				{data.allWpPost.nodes.map((post) => (
					<PostCard
						title={post.title}
						slug={post.slug}
						category={post.categories.nodes[0].name}
						author={post.author.node}
						imageUrl={
							post.featuredImage
								? post.featuredImage.node.sourceUrl
								: ''
						}
						key={post.slug}
					/>
				))}
			</Layout>
		</>
	)
}

export const pageQuery = graphql`
	query {
		allWpPost(
			filter: {
				categories: {
					nodes: { elemMatch: { slug: { ne: "updates" } } }
				}
			}
		) {
			nodes {
				slug
				title
				author {
					node {
						name
						avatar {
							url
						}
					}
				}
				featuredImage {
					node {
						sourceUrl
					}
				}
				categories {
					nodes {
						name
					}
				}
			}
		}
	}
`

export default Latest
